import AppContentHeader from "./AppParts/AppContentHeader";
//import AppFooter from "../Footer/AppFooter";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import AuthCheck from "../Auth/AuthCheck";
import Image from "next/image";
//import { toast } from "react-toastify";
//import { setLoginState } from "../../features/app/appSlice";
import { Avatar } from "../@/components/ui/avatar";
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "../@/components/ui/dropdown";
import {
  Navbar,
  NavbarItem,
  NavbarSection,
  NavbarSpacer,
} from "../@/components/ui/navbar";
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarHeading,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  SidebarSpacer,
} from "../@/components/ui/sidebar";
import { SidebarLayout } from "../@/components/ui/sidebar-layout";

import {
  ArrowRightStartOnRectangleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Cog8ToothIcon,
  LightBulbIcon,
  PlusIcon,
  ShieldCheckIcon,
  UserCircleIcon,
  // blocklist
  FingerPrintIcon,
  ServerStackIcon
} from "@heroicons/react/16/solid";
import {
  Cog6ToothIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  SparklesIcon,
  Square2StackIcon,
  TicketIcon,

} from "@heroicons/react/20/solid";
import { usePathname } from "next/navigation";

export function AppLayout({ events, children }) {
  let pathname = usePathname();

  const router = useRouter();
  const dispatch = useDispatch();
  const { is_logged_in } = useSelector((state) => state.app);
  const { last_sign_in } = useSelector((state) => state.auth);

  const [path,setPath] = useState("");

  useEffect(() => {
    if (!is_logged_in) {
      //router.push("/auth/login");
    }
  }, [is_logged_in]);

  useEffect(() => {
    if (pathname ) {
      setPath(pathname);
    }
  }, [pathname]);


  return (
    <SidebarLayout
      sidebar={
        <Sidebar>
          <SidebarHeader>
            <Dropdown>
              <Image className="filter sepia-0" src="/assets/logo.png" alt="logo" width={250} height={40} />
              <DropdownMenu
                className="min-w-80 lg:min-w-64"
                anchor="bottom start"
              >
                <DropdownItem href="/settings">
                  <Cog8ToothIcon />
                  <DropdownLabel>Settings</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem href="#">
                  <Avatar slot="icon" src="/teams/catalyst.svg" />
                  <DropdownLabel>Catalyst</DropdownLabel>
                </DropdownItem>
                <DropdownItem href="#">
                  <Avatar
                    slot="icon"
                    initials="BE"
                    className="bg-purple-500 text-white"
                  />
                  <DropdownLabel>Big Events</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem href="#">
                  <PlusIcon />
                  <DropdownLabel>New team&hellip;</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </SidebarHeader>

          <SidebarBody>
            <SidebarSection>
              <SidebarItem href="/" current={pathname === "/"}>
                <HomeIcon />
                <SidebarLabel>Home</SidebarLabel>
              </SidebarItem>
              <SidebarItem
                href="/services"
                current={path.startsWith("/services")}
              >
                <ServerStackIcon />
                <SidebarLabel>Services</SidebarLabel>
              </SidebarItem>
              <SidebarItem
                href="/impersonate"
                current={path.startsWith("/impersonate")}
              >
                <UserCircleIcon />
                <SidebarLabel>Impersonate</SidebarLabel>
              </SidebarItem>
              <SidebarItem
                href="/accounting"
                current={path.startsWith("/accounting")}
              >
                <TicketIcon />
                <SidebarLabel>Accounting</SidebarLabel>
              </SidebarItem>
              <SidebarItem
                href="/sessions"
                current={path.startsWith("/sessions")}
              >
                <Cog6ToothIcon />
                <SidebarLabel>Sessions</SidebarLabel>
              </SidebarItem>
              <SidebarItem
                href="/activity-logs"
                current={path.startsWith("/activity-logs")}
              >
                <ShieldCheckIcon />
                <SidebarLabel>Activity Logs</SidebarLabel>
              </SidebarItem>
              <SidebarItem
                href="/ip-blacklist"
                current={path.startsWith("/ip-blacklist")}
              >
                <FingerPrintIcon />
                <SidebarLabel>IP Blacklist</SidebarLabel>
              </SidebarItem>
            </SidebarSection>

            <SidebarSpacer />

            <SidebarSection>
              <SidebarItem href="#">
                <QuestionMarkCircleIcon />
                <SidebarLabel>Support</SidebarLabel>
              </SidebarItem>
              <SidebarItem href="#">
                <SparklesIcon />
                <SidebarLabel>Changelog</SidebarLabel>
              </SidebarItem>
            </SidebarSection>
          </SidebarBody>
        </Sidebar>
      }
    >
      <div className="relative h-full w-full bottom-0 top-0">{children}</div>
    </SidebarLayout>
  );
}

export default AppLayout;
